import React, { useEffect } from "react"
import { Link } from "gatsby"
import { animateScroll as scroll } from "react-scroll"
import Layout from "@components/layout";
import Header from "@components/header/header";
import Banner from "@components/banner";
import PriceHome from "@components/price/price-home";
import BlogHome from "@components/blog/blog-home";
import ServicesHome from "@components/services/services-home";
import FAQ from "@components/faq";
import Footer from "@components/footer";
import MobileNav from "@components/mobile-nav";

const Price = () => {
  useEffect(() => {
    // Scroll to the target element when the URL hash changes
    const scrollOptions = { duration: 500 }
    scroll.scrollTo(window.location.hash.substring(1), scrollOptions)
  }, [])

  return (
    <Layout pageTitle="Home Page || WICEBOT">
    <Header />
    <Banner />
    <ServicesHome />
    {/*<CallToAction />
    <Video />
    <AppFeature />*/}
    <PriceHome />
    {/*<TestimonialsHome />
    <AppShot />*/}
    <FAQ />
    <BlogHome />
    {/*<Brands />*/}
    {/*<Subscribe />*/}
    <Footer />
    <MobileNav />
  </Layout>
  )
}
export default Price;
